@import "https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700|Roboto:300,400,500,700,900&amp;display=swap";
@font-face {
  font-family: Linearicons-Free;
  src: url("Linearicons-Free.ada66428.eot");
  src: url("Linearicons-Free.ada66428.eot#iefixw118d") format("embedded-opentype"), url("Linearicons-Free.08a50030.woff2") format("woff2"), url("Linearicons-Free.9e0ae55c.woff") format("woff"), url("Linearicons-Free.adb6f816.ttf") format("truetype"), url("Linearicons-Free.8871e643.svg#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}

.lnr {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Linearicons-Free;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.lnr-home:before {
  content: "";
}

.lnr-apartment:before {
  content: "";
}

.lnr-pencil:before {
  content: "";
}

.lnr-magic-wand:before {
  content: "";
}

.lnr-drop:before {
  content: "";
}

.lnr-lighter:before {
  content: "";
}

.lnr-poop:before {
  content: "";
}

.lnr-sun:before {
  content: "";
}

.lnr-moon:before {
  content: "";
}

.lnr-cloud:before {
  content: "";
}

.lnr-cloud-upload:before {
  content: "";
}

.lnr-cloud-download:before {
  content: "";
}

.lnr-cloud-sync:before {
  content: "";
}

.lnr-cloud-check:before {
  content: "";
}

.lnr-database:before {
  content: "";
}

.lnr-lock:before {
  content: "";
}

.lnr-cog:before {
  content: "";
}

.lnr-trash:before {
  content: "";
}

.lnr-dice:before {
  content: "";
}

.lnr-heart:before {
  content: "";
}

.lnr-star:before {
  content: "";
}

.lnr-star-half:before {
  content: "";
}

.lnr-star-empty:before {
  content: "";
}

.lnr-flag:before {
  content: "";
}

.lnr-envelope:before {
  content: "";
}

.lnr-paperclip:before {
  content: "";
}

.lnr-inbox:before {
  content: "";
}

.lnr-eye:before {
  content: "";
}

.lnr-printer:before {
  content: "";
}

.lnr-file-empty:before {
  content: "";
}

.lnr-file-add:before {
  content: "";
}

.lnr-enter:before {
  content: "";
}

.lnr-exit:before {
  content: "";
}

.lnr-graduation-hat:before {
  content: "";
}

.lnr-license:before {
  content: "";
}

.lnr-music-note:before {
  content: "";
}

.lnr-film-play:before {
  content: "";
}

.lnr-camera-video:before {
  content: "";
}

.lnr-camera:before {
  content: "";
}

.lnr-picture:before {
  content: "";
}

.lnr-book:before {
  content: "";
}

.lnr-bookmark:before {
  content: "";
}

.lnr-user:before {
  content: "";
}

.lnr-users:before {
  content: "";
}

.lnr-shirt:before {
  content: "";
}

.lnr-store:before {
  content: "";
}

.lnr-cart:before {
  content: "";
}

.lnr-tag:before {
  content: "";
}

.lnr-phone-handset:before {
  content: "";
}

.lnr-phone:before {
  content: "";
}

.lnr-pushpin:before {
  content: "";
}

.lnr-map-marker:before {
  content: "";
}

.lnr-map:before {
  content: "";
}

.lnr-location:before {
  content: "";
}

.lnr-calendar-full:before {
  content: "";
}

.lnr-keyboard:before {
  content: "";
}

.lnr-spell-check:before {
  content: "";
}

.lnr-screen:before {
  content: "";
}

.lnr-smartphone:before {
  content: "";
}

.lnr-tablet:before {
  content: "";
}

.lnr-laptop:before {
  content: "";
}

.lnr-laptop-phone:before {
  content: "";
}

.lnr-power-switch:before {
  content: "";
}

.lnr-bubble:before {
  content: "";
}

.lnr-heart-pulse:before {
  content: "";
}

.lnr-construction:before {
  content: "";
}

.lnr-pie-chart:before {
  content: "";
}

.lnr-chart-bars:before {
  content: "";
}

.lnr-gift:before {
  content: "";
}

.lnr-diamond:before {
  content: "";
}

.lnr-linearicons:before {
  content: "";
}

.lnr-dinner:before {
  content: "";
}

.lnr-coffee-cup:before {
  content: "";
}

.lnr-leaf:before {
  content: "";
}

.lnr-paw:before {
  content: "";
}

.lnr-rocket:before {
  content: "";
}

.lnr-briefcase:before {
  content: "";
}

.lnr-bus:before {
  content: "";
}

.lnr-car:before {
  content: "";
}

.lnr-train:before {
  content: "";
}

.lnr-bicycle:before {
  content: "";
}

.lnr-wheelchair:before {
  content: "";
}

.lnr-select:before {
  content: "";
}

.lnr-earth:before {
  content: "";
}

.lnr-smile:before {
  content: "";
}

.lnr-sad:before {
  content: "";
}

.lnr-neutral:before {
  content: "";
}

.lnr-mustache:before {
  content: "";
}

.lnr-alarm:before {
  content: "";
}

.lnr-bullhorn:before {
  content: "";
}

.lnr-volume-high:before {
  content: "";
}

.lnr-volume-medium:before {
  content: "";
}

.lnr-volume-low:before {
  content: "";
}

.lnr-volume:before {
  content: "";
}

.lnr-mic:before {
  content: "";
}

.lnr-hourglass:before {
  content: "";
}

.lnr-undo:before {
  content: "";
}

.lnr-redo:before {
  content: "";
}

.lnr-sync:before {
  content: "";
}

.lnr-history:before {
  content: "";
}

.lnr-clock:before {
  content: "";
}

.lnr-download:before {
  content: "";
}

.lnr-upload:before {
  content: "";
}

.lnr-enter-down:before {
  content: "";
}

.lnr-exit-up:before {
  content: "";
}

.lnr-bug:before {
  content: "";
}

.lnr-code:before {
  content: "";
}

.lnr-link:before {
  content: "";
}

.lnr-unlink:before {
  content: "";
}

.lnr-thumbs-up:before {
  content: "";
}

.lnr-thumbs-down:before {
  content: "";
}

.lnr-magnifier:before {
  content: "";
}

.lnr-cross:before {
  content: "";
}

.lnr-menu:before {
  content: "";
}

.lnr-list:before {
  content: "";
}

.lnr-chevron-up:before {
  content: "";
}

.lnr-chevron-down:before {
  content: "";
}

.lnr-chevron-left:before {
  content: "";
}

.lnr-chevron-right:before {
  content: "";
}

.lnr-arrow-up:before {
  content: "";
}

.lnr-arrow-down:before {
  content: "";
}

.lnr-arrow-left:before {
  content: "";
}

.lnr-arrow-right:before {
  content: "";
}

.lnr-move:before {
  content: "";
}

.lnr-warning:before {
  content: "";
}

.lnr-question-circle:before {
  content: "";
}

.lnr-menu-circle:before {
  content: "";
}

.lnr-checkmark-circle:before {
  content: "";
}

.lnr-cross-circle:before {
  content: "";
}

.lnr-plus-circle:before {
  content: "";
}

.lnr-circle-minus:before {
  content: "";
}

.lnr-arrow-up-circle:before {
  content: "";
}

.lnr-arrow-down-circle:before {
  content: "";
}

.lnr-arrow-left-circle:before {
  content: "";
}

.lnr-arrow-right-circle:before {
  content: "";
}

.lnr-chevron-up-circle:before {
  content: "";
}

.lnr-chevron-down-circle:before {
  content: "";
}

.lnr-chevron-left-circle:before {
  content: "";
}

.lnr-chevron-right-circle:before {
  content: "";
}

.lnr-crop:before {
  content: "";
}

.lnr-frame-expand:before {
  content: "";
}

.lnr-frame-contract:before {
  content: "";
}

.lnr-layers:before {
  content: "";
}

.lnr-funnel:before {
  content: "";
}

.lnr-text-format:before {
  content: "";
}

.lnr-text-format-remove:before {
  content: "";
}

.lnr-text-size:before {
  content: "";
}

.lnr-bold:before {
  content: "";
}

.lnr-italic:before {
  content: "";
}

.lnr-underline:before {
  content: "";
}

.lnr-strikethrough:before {
  content: "";
}

.lnr-highlight:before {
  content: "";
}

.lnr-text-align-left:before {
  content: "";
}

.lnr-text-align-center:before {
  content: "";
}

.lnr-text-align-right:before {
  content: "";
}

.lnr-text-align-justify:before {
  content: "";
}

.lnr-line-spacing:before {
  content: "";
}

.lnr-indent-increase:before {
  content: "";
}

.lnr-indent-decrease:before {
  content: "";
}

.lnr-pilcrow:before {
  content: "";
}

.lnr-direction-ltr:before {
  content: "";
}

.lnr-direction-rtl:before {
  content: "";
}

.lnr-page-break:before {
  content: "";
}

.lnr-sort-alpha-asc:before {
  content: "";
}

.lnr-sort-amount-asc:before {
  content: "";
}

.lnr-hand:before {
  content: "";
}

.lnr-pointer-up:before {
  content: "";
}

.lnr-pointer-right:before {
  content: "";
}

.lnr-pointer-down:before {
  content: "";
}

.lnr-pointer-left:before {
  content: "";
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  animation: fa-spin 1s steps(8, end) infinite;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-haykal:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("fa-brands-400.3b225ccd.eot");
  src: url("fa-brands-400.3b225ccd.eot#iefix") format("embedded-opentype"), url("fa-brands-400.94971114.woff2") format("woff2"), url("fa-brands-400.cf1432b0.woff") format("woff"), url("fa-brands-400.2dde8f4c.ttf") format("truetype"), url("fa-brands-400.09cbd7c7.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("fa-regular-400.1723cf1a.eot");
  src: url("fa-regular-400.1723cf1a.eot#iefix") format("embedded-opentype"), url("fa-regular-400.d3abeeca.woff2") format("woff2"), url("fa-regular-400.c88515ed.woff") format("woff"), url("fa-regular-400.37a32b11.ttf") format("truetype"), url("fa-regular-400.53f35844.svg#fontawesome") format("svg");
}

.far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("fa-solid-900.e1e17b7a.eot");
  src: url("fa-solid-900.e1e17b7a.eot#iefix") format("embedded-opentype"), url("fa-solid-900.cff1f7b5.woff2") format("woff2"), url("fa-solid-900.4c2d0262.woff") format("woff"), url("fa-solid-900.7089b396.ttf") format("truetype"), url("fa-solid-900.563baddb.svg#fontawesome") format("svg");
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free";
}

.fa, .fas {
  font-weight: 900;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="datetime-local"], input[type="month"], input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-auto {
  width: auto;
  max-width: none;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-xl-auto {
    width: auto;
    max-width: none;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  background-color: #0000;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td, .table-sm th {
  padding: .3rem;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  background-color: #00000013;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(2.25rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745;
}

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #28a74540;
}

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .custom-file-label:after, .was-validated .custom-file-input:valid ~ .custom-file-label:after {
  border-color: inherit;
}

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #dc354540;
}

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .custom-file-label:after, .was-validated .custom-file-input:invalid ~ .custom-file-label:after {
  border-color: inherit;
}

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select, .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-primary {
  color: #007bff;
  background-color: #0000;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: #0000;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  background-color: #0000;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: #0000;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: #0000;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: #0000;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: #0000;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  background-color: #0000;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  background-color: #0000;
  font-weight: 400;
}

.btn-link:hover {
  color: #0056b3;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link.focus, .btn-link:focus {
  box-shadow: none;
  border-color: #0000;
  text-decoration: underline;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
}

.dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  width: 0;
  height: 0;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: 0 auto;
  position: relative;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  width: 1%;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 2;
  position: relative;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="checkbox"], .input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  height: calc(2.875rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  height: calc(1.8125rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-control-input:active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(2.25rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #80bdff80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%;
}

.custom-file {
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:focus ~ .custom-file-label:after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-label {
  z-index: 1;
  height: calc(2.25rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: 2.25rem;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  appearance: none;
  background-color: #0000;
  padding-left: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.card-body {
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-deck {
  flex-direction: column;
  display: flex;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-deck .card {
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
  }
}

.card-group {
  flex-direction: column;
  display: flex;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: .25rem;
  }

  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child), .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  background-color: #0062cc;
  text-decoration: none;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  background-color: #545b62;
  text-decoration: none;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  background-color: #1e7e34;
  text-decoration: none;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  background-color: #117a8b;
  text-decoration: none;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #212529;
  background-color: #d39e00;
  text-decoration: none;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  background-color: #bd2130;
  text-decoration: none;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #212529;
  background-color: #dae0e5;
  text-decoration: none;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  background-color: #1d2124;
  text-decoration: none;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

button.close {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  content: "";
  display: block;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before, .bs-tooltip-top .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"], .bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before, .bs-tooltip-right .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .bs-tooltip-bottom .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"], .bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before, .bs-tooltip-left .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"], .bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-top .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:after, .bs-popover-top .arrow:before {
  border-width: .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:before {
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-top .arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"], .bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^="right"] .arrow, .bs-popover-right .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:after, .bs-popover-right .arrow:before {
  border-width: .5rem .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:before {
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-right .arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow, .bs-popover-bottom .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:after, .bs-popover-bottom .arrow:before {
  border-width: 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:before {
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-bottom .arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[x-placement^="left"], .bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow, .bs-popover-left .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:after, .bs-popover-left .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:before {
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-left .arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-item {
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
  align-items: center;
  display: none;
  position: relative;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  transition: transform .6s, -webkit-transform .6s;
  display: block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item-next, .carousel-item-prev, .carousel-item.active {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.active.carousel-item-right, .carousel-item-next {
  transform: translateX(100%);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .active.carousel-item-right, .carousel-item-next {
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left, .carousel-item-prev {
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .active.carousel-item-left, .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .6s;
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev, .carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active {
  transform: translateX(0);
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev, .carousel-fade .carousel-item-next, .carousel-fade .carousel-item-prev, .carousel-fade .carousel-item.active {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-next, .carousel-control-prev {
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff80;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.carousel-indicators li:before {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
}

.carousel-indicators li:after {
  width: 100%;
  height: 10px;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  thead {
    display: table-header-group;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  animation-duration: .75s;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  40%, 43%, 70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scaleX(1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  0%, 11.1%, to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    transform: perspective(400px)rotateY(-1turn);
  }

  0%, 40% {
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px)translateZ(150px)rotateY(-190deg);
  }

  50% {
    transform: perspective(400px)translateZ(150px)rotateY(-170deg);
  }

  50%, 80% {
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px)scale3d(.95, .95, .95);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }

  0%, 40% {
    animation-timing-function: ease-in;
  }

  40% {
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }

  0%, 40% {
    animation-timing-function: ease-in;
  }

  40% {
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    transform: skewX(20deg);
  }

  60%, 80% {
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  0%, to {
    -webkit-transform-origin: center;
  }

  to {
    transform-origin: center;
    opacity: 1;
    transform: none;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: center;
  }

  to {
    transform-origin: center;
    opacity: 0;
    transform: rotate(200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
  }

  0%, 20%, 60% {
    -webkit-transform-origin: 0 0;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform-origin: 0 0;
    transform: rotate(80deg);
  }

  40%, 80% {
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale3d(.3, .3, .3);
  }

  50%, to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 100%;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  display: none;
  position: relative;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
}

.owl-carousel .owl-item img {
  width: 100%;
  -webkit-transform-style: preserve-3d;
  display: block;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  height: 100%;
  background: #000;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  height: 80px;
  width: 80px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  background: url("owl.video.play.38d394c2.html") no-repeat;
  margin-top: -40px;
  margin-left: -40px;
  transition: transform .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

@font-face {
  font-family: lightcase;
  src: url("lightcaseeead.38d394c2.html");
  src: url("lightcaseeead.38d394c2.html#iefix") format("embedded-opentype"), url("lightcaseeead-2.38d394c2.html") format("woff"), url("lightcaseeead-3.38d394c2.html") format("truetype"), url("lightcaseeead-4.38d394c2.html#lightcase") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class*="lightcase-icon-"]:before {
  speak: none;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  font-family: lightcase, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.lightcase-icon-play:before {
  content: "";
}

.lightcase-icon-pause:before {
  content: "";
}

.lightcase-icon-close:before {
  content: "";
}

.lightcase-icon-prev:before {
  content: "";
}

.lightcase-icon-next:before {
  content: "";
}

.lightcase-icon-spin:before {
  content: "";
}

@keyframes lightcase-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

#lightcase-case {
  z-index: 2002;
  text-align: left;
  text-shadow: 0 0 10px #00000080;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
}

@media screen and (max-width: 640px) {
  html[data-lc-type="inline"] #lightcase-case, html[data-lc-type="ajax"] #lightcase-case {
    padding: 55px 0 70px;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    position: fixed !important;
    inset: 0 !important;
    overflow: auto !important;
  }
}

@media screen and (min-width: 641px) {
  html:not([data-lc-type="error"]) #lightcase-content {
    z-index: 1;
    text-shadow: none;
    -o-box-shadow: 0 0 30px #00000080;
    -webkit-backface-visibility: hidden;
    background-color: #fff;
    position: relative;
    box-shadow: 0 0 30px #00000080;
  }
}

@media screen and (min-width: 641px) {
  html[data-lc-type="image"] #lightcase-content, html[data-lc-type="video"] #lightcase-content {
    background-color: #333;
  }
}

html[data-lc-type="inline"] #lightcase-content, html[data-lc-type="ajax"] #lightcase-content, html[data-lc-type="error"] #lightcase-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width: 640px) {
  html[data-lc-type="inline"] #lightcase-content, html[data-lc-type="ajax"] #lightcase-content, html[data-lc-type="error"] #lightcase-content {
    width: auto !important;
    height: auto !important;
    background: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
  }
}

html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner, html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner, html[data-lc-type="error"] #lightcase-content .lightcase-contentInner {
  box-sizing: border-box;
  -o-box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner, html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner, html[data-lc-type="error"] #lightcase-content .lightcase-contentInner {
    padding: 15px;
  }

  html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner, html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner > *, html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner, html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner > *, html[data-lc-type="error"] #lightcase-content .lightcase-contentInner, html[data-lc-type="error"] #lightcase-content .lightcase-contentInner > * {
    width: 100% !important;
    max-width: none !important;
  }

  html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner > :not(iframe), html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner > :not(iframe), html[data-lc-type="error"] #lightcase-content .lightcase-contentInner > :not(iframe) {
    height: auto !important;
    max-height: none !important;
  }
}

@media screen and (max-width: 640px) {
  html.lightcase-isMobileDevice[data-lc-type="iframe"] #lightcase-content .lightcase-contentInner iframe {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
}

@media screen and (max-width: 640px) and (min-width: 641px) {
  html[data-lc-type="image"] #lightcase-content .lightcase-contentInner, html[data-lc-type="video"] #lightcase-content .lightcase-contentInner {
    line-height: .75;
  }
}

html[data-lc-type="image"] #lightcase-content .lightcase-contentInner {
  position: relative;
  overflow: hidden !important;
}

@media screen and (max-width: 640px) {
  html[data-lc-type="inline"] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type="ajax"] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap, html[data-lc-type="error"] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    width: auto !important;
    height: auto !important;
    background: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
  }
}

@media screen and (min-width: 641px) {
  html:not([data-lc-type="error"]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    padding: 30px;
    overflow: auto;
  }
}

@media screen and (max-width: 640px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #aaa;
  }
}

@media screen and (min-width: 641px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #333;
  }
}

#lightcase-case p.lightcase-error {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #aaa;
  margin: 0;
  font-size: 17px;
  overflow: hidden;
}

@media screen and (max-width: 640px) {
  #lightcase-case p.lightcase-error {
    padding: 30px 0;
  }
}

@media screen and (min-width: 641px) {
  #lightcase-case p.lightcase-error {
    padding: 0;
  }
}

.lightcase-open body {
  overflow: hidden;
}

.lightcase-isMobileDevice .lightcase-open body {
  max-width: 100%;
  max-height: 100%;
}

#lightcase-info {
  padding-top: 15px;
  position: absolute;
}

#lightcase-info #lightcase-title, #lightcase-info #lightcase-caption {
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1.5;
}

#lightcase-info #lightcase-title {
  color: #aaa;
  font-size: 17px;
}

@media screen and (max-width: 640px) {
  #lightcase-info #lightcase-title {
    max-width: 87.5%;
    background: #333;
    padding: 5px 15px;
    position: fixed;
    top: 10px;
    left: 0;
  }
}

#lightcase-info #lightcase-caption {
  clear: both;
  color: #aaa;
  font-size: 13px;
}

#lightcase-info #lightcase-sequenceInfo {
  color: #aaa;
  font-size: 11px;
}

@media screen and (max-width: 640px) {
  .lightcase-fullScreenMode #lightcase-info {
    padding-left: 15px;
    padding-right: 15px;
  }

  html:not([data-lc-type="image"]):not([data-lc-type="video"]):not([data-lc-type="flash"]):not([data-lc-type="error"]) #lightcase-info {
    position: static;
  }
}

#lightcase-loading {
  z-index: 9999;
  width: 1.123em;
  height: auto;
  text-align: center;
  text-shadow: none;
  z-index: 2001;
  opacity: 1;
  text-shadow: 0 0 15px #fff;
  -moz-transform-origin: 50% 53%;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: 32px;
  line-height: 1;
  animation: lightcase-spin .5s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
}

#lightcase-loading, #lightcase-loading:focus {
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  -o-transition: color, opacity, ease-in-out .25s;
  text-decoration: none;
  transition: color, opacity, all .25s ease-in-out;
}

#lightcase-loading > span {
  text-indent: -9999px;
  display: inline-block;
}

a[class*="lightcase-icon-"] {
  z-index: 9999;
  width: 1.123em;
  height: auto;
  text-align: center;
  text-shadow: none;
  cursor: pointer;
  outline: none;
  font-size: 38px;
  line-height: 1;
  position: fixed;
}

a[class*="lightcase-icon-"], a[class*="lightcase-icon-"]:focus {
  color: #fff9;
  -webkit-tap-highlight-color: transparent;
  -o-transition: color, opacity, ease-in-out .25s;
  text-decoration: none;
  transition: color, opacity, all .25s ease-in-out;
}

a[class*="lightcase-icon-"] > span {
  text-indent: -9999px;
  display: inline-block;
}

a[class*="lightcase-icon-"]:hover {
  color: #fff;
  text-shadow: 0 0 15px #fff;
}

.lightcase-isMobileDevice a[class*="lightcase-icon-"]:hover {
  color: #aaa;
  text-shadow: none;
}

a[class*="lightcase-icon-"].lightcase-icon-close {
  opacity: 0;
  outline: none;
  margin: 0;
  position: fixed;
  top: 15px;
  bottom: auto;
  right: 15px;
}

a[class*="lightcase-icon-"].lightcase-icon-prev {
  left: 15px;
}

a[class*="lightcase-icon-"].lightcase-icon-next {
  right: 15px;
}

a[class*="lightcase-icon-"].lightcase-icon-pause, a[class*="lightcase-icon-"].lightcase-icon-play {
  margin-left: -.5em;
  left: 50%;
}

@media screen and (min-width: 641px) {
  a[class*="lightcase-icon-"].lightcase-icon-pause, a[class*="lightcase-icon-"].lightcase-icon-play {
    opacity: 0;
  }
}

@media screen and (max-width: 640px) {
  a[class*="lightcase-icon-"] {
    font-size: 24px;
    bottom: 15px;
  }
}

@media screen and (min-width: 641px) {
  a[class*="lightcase-icon-"] {
    margin-bottom: -.5em;
    bottom: 50%;
  }

  a[class*="lightcase-icon-"]:hover, #lightcase-case:hover ~ a[class*="lightcase-icon-"] {
    opacity: 1;
  }
}

#lightcase-overlay {
  width: 100%;
  min-height: 100%;
  z-index: 2000;
  background: #333;
  display: none;
  position: fixed;
  top: -9999px;
  bottom: -9999px;
  left: 0;
}

@media screen and (max-width: 640px) {
  #lightcase-overlay {
    opacity: 1 !important;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

html {
  scroll-behavior: smooth;
}

body {
  color: #000;
  background-color: #e8f5e9;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.625em;
  position: relative;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

select {
  display: block;
}

figure {
  margin: 0;
}

a {
  -o-transition: all .3s ease 0s;
  transition: all .3s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover, .btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus {
  outline: 0;
  text-decoration: none;
}

.fa {
  font-size: larger;
}

h1, h2, h3, h4, h5, h6 {
  color: #2f2d52;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.2em;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #2f2d52;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  color: #2f2d52;
  font-size: 14px;
}

td, th {
  border-radius: 0;
}

.clear:before, .clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-90 {
  padding-left: 90px;
}

.p-40 {
  padding: 40px;
}

.transition {
  -o-transition: all .3s ease 0s;
  transition: all .3s;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.flex-column {
  -moz-flex-direction: column;
  flex-direction: column;
}

.relative {
  position: relative;
}

.section-gap {
  padding: 150px 0;
}

.section-gap-mini {
  padding: 75px 0;
}

.quote {
  font-style: italic;
}

@media (max-width: 1024px) {
  .section-gap {
    padding: 60px 0;
  }
}

.section-gap-top {
  padding-top: 150px;
}

@media (max-width: 1024px) {
  .section-gap-top {
    padding: 60px 0;
  }
}

.section-gap-bottom-90 {
  padding-bottom: 130px;
}

@media (max-width: 1024px) {
  .section-gap-bottom-90 {
    padding-bottom: 30px;
  }
}

.section-title {
  margin-bottom: 80px;
}

@media (max-width: 1024px) {
  .section-title {
    margin-bottom: 30px;
  }
}

.section-title h2 {
  text-transform: capitalize;
  margin-bottom: 70px;
  font-size: 48px;
  font-weight: 700;
  line-height: 44px;
  position: relative;
}

@media (max-width: 1024px) {
  .section-title h2 {
    margin-bottom: 30px;
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .section-title h2 {
    font-size: 30px;
  }
}

.section-title h2:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #2f2d52;
  border-radius: 0;
  position: absolute;
  bottom: -35px;
  left: 0;
}

@media (max-width: 1024px) {
  .section-title h2:before {
    bottom: -15px;
  }
}

.section-title h2:after {
  content: "";
  width: 100px;
  height: 2px;
  background: #2f2d52;
  position: absolute;
  bottom: -31px;
  left: 0;
}

@media (max-width: 1024px) {
  .section-title h2:after {
    bottom: -11px;
  }
}

.section-title p {
  max-width: 535px;
  margin-bottom: 0;
}

.section-title-center {
  margin-bottom: 80px;
}

@media (max-width: 1024px) {
  .section-title-center {
    margin-bottom: 30px;
  }
}

.section-title-center h2 {
  text-transform: capitalize;
  margin-bottom: 70px;
  font-size: 48px;
  font-weight: 700;
  line-height: 44px;
  position: relative;
}

@media (max-width: 1024px) {
  .section-title-center h2 {
    margin-bottom: 30px;
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .section-title-center h2 {
    font-size: 30px;
  }
}

.section-title-center h2:before {
  content: "";
  width: 100px;
  height: 2px;
  background: #2f2d52;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .section-title-center h2:before {
    bottom: -15px;
  }
}

.section-title-center h2:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #2f2d52;
  border-radius: 0;
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .section-title-center h2:after {
    bottom: -19px;
  }
}

.section-title-center p {
  max-width: 540px;
  margin: 0 auto;
}

.fa-2x {
  font-size: 2em;
  color: #2f2d52 !important;
}

.fa-2x.fa-secondary {
  color: #c0c0ca !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.bottom-space {
  margin-bottom: 10px;
}

b, sup, sub, u, del {
  color: #2f2d52;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5em;
}

.circle-1 {
  width: 50px;
  height: 50px;
  transform-origin: 50%;
  z-index: 3;
  border: 2px solid #6474e5;
  border-radius: 50%;
  animation: fadeInFromTop .5s linear forwards, floating 4s infinite;
  display: block;
  position: absolute;
  top: 50%;
  left: 5%;
}

.circle-2 {
  width: 70px;
  height: 70px;
  transform-origin: 50%;
  z-index: 3;
  border: 2px solid #b9e7b9;
  border-radius: 50%;
  animation: fadeInFromTop .5s linear forwards, floating 4s ease-in-out infinite;
  display: block;
  position: absolute;
  top: 20%;
  left: 83%;
}

.circle-3 {
  width: 40px;
  height: 40px;
  z-index: 3;
  border: 2px solid #b9e7b9;
  border-radius: 50%;
  animation: fadeInFromTop .5s linear forwards, floating 4s ease-in-out infinite;
  display: block;
  position: absolute;
  top: 80%;
  right: 25%;
}

@keyframes floating {
  0% {
    transform: translate(0%)rotate(25deg);
  }

  25% {
    transform: translate(5%, 15%)rotate(25deg);
  }

  50% {
    transform: translate(10%, 5%)rotate(25deg);
  }

  75% {
    transform: translate(0%, 15%)rotate(25deg);
  }

  100% {
    transform: translate(0%)rotate(25deg);
  }
}

@keyframes floating-slow {
  0% {
    transform: translate(0%)rotate(25deg);
  }

  25% {
    transform: translate(1%, 3%)rotate(25deg);
  }

  50% {
    transform: translate(2%, 1%)rotate(25deg);
  }

  75% {
    transform: translate(0%, 3%)rotate(25deg);
  }

  100% {
    transform: translate(0%)rotate(25deg);
  }
}

.triangle {
  z-index: 3;
  position: absolute;
}

.triangle-1 {
  z-index: 3;
  animation: fadeInFromTop .5s linear forwards, floating 6s ease-in-out infinite;
  right: 0;
}

.triangle-1 img {
  height: 50px;
  width: 50px;
  z-index: 3;
  transform: rotate(30deg);
}

.triangle-2 {
  z-index: 3;
  animation: fadeInFromTop .5s linear forwards, floating 8s ease-in-out infinite;
  top: 30%;
  left: 20%;
}

.triangle-2 img {
  width: 75px;
  height: 75px;
  z-index: 3;
  transform: rotate(15deg);
}

.triangle-3 {
  z-index: 3;
  animation: fadeInFromTop .5s linear forwards, floating 10s ease-in-out infinite;
  top: 80%;
  left: 15%;
}

.triangle-3 img {
  width: 45px;
  height: 45px;
  z-index: 3;
  transform: rotate(40deg);
}

.triangle-4 {
  z-index: 3;
  animation: fadeInFromTop .5s linear forwards, floating 5s ease-in-out infinite;
  top: 60%;
  right: 15%;
}

.triangle-4 img {
  width: 45px;
  height: 45px;
  z-index: 3;
  transform: rotate(-40deg);
}

.primary-btn {
  color: #fff;
  min-width: 241px;
  vertical-align: middle;
  z-index: 1;
  cursor: pointer;
  text-align: center;
  backface-visibility: hidden;
  -o-transition: all .3s ease 0s;
  background: #2f2d52;
  border: 1px solid #2f2d52;
  border-radius: 40px;
  align-self: center;
  padding: 0 52px;
  font-size: 15px;
  font-weight: 600;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .primary-btn {
    padding: 0 35px;
  }
}

.primary-btn:before {
  content: attr(data-text);
  width: 100%;
  height: 100%;
  -o-transition: all .3s ease 0s;
  transition: all .3s;
  -o-transition-timing-function: cubic-bezier(.75, 0, .125, 1);
  color: #fff;
  padding: 13px 0;
  transition-timing-function: cubic-bezier(.75, 0, .125, 1);
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .primary-btn:before {
    padding: 8px 0;
  }
}

.primary-btn:hover {
  background: #fff;
  border: 1px solid #2f2d52;
}

.primary-btn:hover:before {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.primary-btn:hover span {
  opacity: 1;
  color: #2f2d52;
  transform: translate3d(0, 0, 0);
}

.primary-btn:hover span:nth-child(1) {
  -o-transition-delay: .01s;
  transition-delay: 10ms;
}

.primary-btn:hover span:nth-child(2) {
  -o-transition-delay: .05s;
  transition-delay: 50ms;
}

.primary-btn:hover span:nth-child(3) {
  -o-transition-delay: .1s;
  transition-delay: .1s;
}

.primary-btn:hover span:nth-child(4) {
  -o-transition-delay: .15s;
  transition-delay: .15s;
}

.primary-btn:hover span:nth-child(5) {
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.primary-btn:hover span:nth-child(6) {
  -o-transition-delay: .25s;
  transition-delay: .25s;
}

.primary-btn:hover span:nth-child(7) {
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.primary-btn:hover span:nth-child(8) {
  -o-transition-delay: .35s;
  transition-delay: .35s;
}

.primary-btn:hover span:nth-child(9) {
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.primary-btn:hover span:nth-child(10) {
  -o-transition-delay: .45s;
  transition-delay: .45s;
}

.primary-btn:hover span:nth-child(11) {
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.primary-btn:hover span:nth-child(12) {
  -o-transition-delay: .55s;
  transition-delay: .55s;
}

.primary-btn:hover span:nth-child(13) {
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.primary-btn:hover span:nth-child(14) {
  -o-transition-delay: .65s;
  transition-delay: .65s;
}

.primary-btn:hover span:nth-child(15) {
  -o-transition-delay: .7s;
  transition-delay: .7s;
}

.primary-btn:hover span:nth-child(16) {
  -o-transition-delay: .75s;
  transition-delay: .75s;
}

.primary-btn:hover span:nth-child(17) {
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.primary-btn:hover span:nth-child(18) {
  -o-transition-delay: .85s;
  transition-delay: .85s;
}

.primary-btn:hover span:nth-child(19) {
  -o-transition-delay: .95s;
  transition-delay: .95s;
}

.primary-btn:hover span:nth-child(20) {
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.primary-btn span {
  opacity: 0;
  color: #fff;
  -o-transition: all .3s ease 0s;
  transition: all .3s;
  -o-transition-timing-function: cubic-bezier(.75, 0, .125, 1);
  padding: 12px 0;
  transition-timing-function: cubic-bezier(.75, 0, .125, 1);
  display: inline-block;
  transform: translate3d(10px, 10px, 0);
}

@media (max-width: 767px) {
  .primary-btn span {
    padding: 8px 0;
  }
}

.primary-btn.white {
  color: #e45447;
  background: #fff;
  border: 1px solid #a3cd98;
}

.primary-btn.white span, .primary-btn.white:before {
  color: #2f2d52;
}

.primary-btn.white:hover {
  color: #fff;
  background: #a3cd98;
}

.primary-btn.white:hover span {
  color: #fff;
}

.main-menu {
  padding-top: 20px;
}

#header {
  z-index: 997;
  padding: 0 0 20px;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#header.header-scrolled {
  background: #fff;
  transition: all .5s;
  position: fixed;
  box-shadow: -21.213px 21.213px 30px #9e9e9e4d;
}

#header.header-scrolled #nav-menu-container {
  margin-left: auto;
}

#header.header-scrolled.dark {
  background: #2f2d52;
}

#logo {
  margin-left: 15px;
}

@media (max-width: 673px) {
  #logo {
    margin-left: 20px;
  }
}

#header #logo h1 {
  letter-spacing: 3px;
  margin: 0;
  padding: 0;
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #000;
  border-left: 4px solid #2f2d52;
  padding-left: 10px;
}

#header #logo img {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
  }

  #header #logo img {
    max-height: 40px;
  }
}

.home-banner-area .header-social {
  margin: 30px 0 50px;
}

@media (max-width: 1024px) {
  .home-banner-area .header-social {
    margin: 15px 0 30px;
  }
}

.home-banner-area .header-social a {
  margin-right: 20px;
}

.home-banner-area .header-social a i {
  color: #eaf1f9;
  -o-transition: all .3s ease 0s;
  font-size: 36px;
  transition: all .3s;
}

@media (min-width: 768px) {
  .d-xs {
    display: none;
  }
}

@media (max-width: 768px) {
  .d-xs {
    flex-grow: 1;
    display: inline;
  }

  .home-banner-area .header-social a i {
    color: #fff;
    padding-top: 8px;
    font-size: 25px;
  }

  .home-banner-area .banner-content {
    height: 100vh;
    padding-left: 60px;
    padding-right: 60px;
  }

  .home-banner-area .banner-content h1 {
    text-align: center;
    margin-bottom: 5px;
  }

  .home-banner-area .banner-content .header-social {
    flex-direction: column;
    display: flex;
  }

  .banner-content .designation {
    max-width: 100%;
  }

  .banner-content .primary-btn {
    display: block;
  }

  .home-banner-area .header-social a {
    text-align: left;
    backface-visibility: hidden;
    color: #fff;
    cursor: pointer;
    min-width: 241px;
    text-align: center;
    -o-transition: all .3s ease 0s;
    vertical-align: middle;
    z-index: 1;
    background: #2f2d52;
    border: 1px solid #2f2d52;
    border-radius: 40px;
    flex-direction: row;
    margin-top: 5px;
    margin-right: 0;
    padding: 0 52px;
    font-size: 15px;
    font-weight: 600;
    transition: all .3s;
    display: flex;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .home-banner-area .header-social a i {
    font-size: 25px;
  }
}

.home-banner-area .header-social a i:hover {
  color: #2f2d52;
}

.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  z-index: 99;
  background: #ffffffe6;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.nav-menu li {
  white-space: nowrap;
  position: relative;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul, .nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
  text-align: left;
}

.sf-arrows .sf-with-ul {
  padding-right: 28px;
}

.sf-arrows .sf-with-ul:after {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  position: absolute;
  right: 15px;
}

.sf-arrows ul .sf-with-ul:after {
  content: "";
}

#nav-menu-container {
  margin: 0 0 0 140px;
}

.nav-menu a {
  color: #2f2d52;
  text-transform: capitalize;
  outline: none;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.nav-menu a.active, .nav-menu a:hover {
  color: #2f2d52 !important;
}

.nav-menu.white a {
  color: #fff;
}

.nav-menu > li {
  margin-right: 50px;
}

.nav-menu > li:last-child {
  margin-right: 0;
}

.nav-menu ul {
  background: #fff;
  margin: 33px 0 0;
  padding: 10px;
  box-shadow: 0 0 30px #7f89a140;
}

.nav-menu ul.dark {
  background: #2f2d52;
}

.nav-menu ul li {
  transition: all .3s;
}

.nav-menu ul li a {
  color: #333;
  text-transform: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  transition: all .3s;
  display: block;
}

.nav-menu ul li:hover > a {
  color: #2f2d52;
}

.nav-menu ul ul {
  margin: 0;
}

#mobile-nav-toggle {
  z-index: 999;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  font-size: 24px;
  transition: all .4s;
  display: none;
  position: fixed;
  top: 60px;
  right: 15px;
}

@media (max-width: 800px) {
  #mobile-nav-toggle {
    top: 26px;
  }
}

#mobile-nav-toggle i {
  color: #2f2d52;
  padding: 10px;
  font-weight: 900;
}

@media (max-width: 800px) {
  #mobile-nav-toggle {
    display: inline;
  }

  #nav-menu-container {
    display: none;
  }
}

#mobile-nav {
  z-index: 998;
  width: 260px;
  background: #fff;
  padding-top: 18px;
  transition: all .4s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -260px;
  overflow-y: auto;
}

#mobile-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #2f2d52;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  padding: 10px 22px 10px 15px;
  font-size: 13px;
  font-weight: bolder;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

#mobile-nav ul li a:hover {
  color: #2f2d52;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  z-index: 99;
  cursor: pointer;
  color: #fff;
  padding: 15px;
  position: absolute;
  right: 0;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #2f2d52;
}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active {
  color: #2f2d52;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  background: #000000b3;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

.home-banner-area {
  background: url("home-banner.3b4c03b3.png") center / cover;
}

@media (max-width: 769px) {
  .home-banner-area .fullscreen {
    min-height: 600px !important;
  }
}

.banner-area {
  background: #a3cd98;
}

.banner-content {
  text-align: left;
}

.banner-content h1 {
  margin-top: 25%;
  margin-bottom: 30px;
  font-size: 60px;
  font-weight: 700;
  line-height: 66px;
}

@media (max-width: 1024px) {
  .banner-content h1 {
    font-size: 45px;
  }
}

@media (max-width: 576px) {
  .banner-content h1 {
    font-size: 35px;
  }
}

@media (max-width: 325px) {
  .banner-content h1 {
    font-size: 32px;
  }
}

.banner-content .designation {
  max-width: 390px;
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
}

@media (max-width: 576px) {
  .banner-content .designation {
    font-size: 25px;
  }
}

.banner-content .designation .designer, .banner-content .designation .developer {
  color: #2f2d52;
}

.banner-img {
  text-align: right;
  position: relative;
  left: 160px;
}

@media (max-width: 1480px) {
  .banner-img {
    left: 0;
  }
}

@media (max-width: 991px) {
  .banner-img {
    display: none;
  }
}

@media (max-width: 1480px) {
  .banner-img img {
    max-width: 80%;
  }
}

@media (max-width: 626px) {
  .about-right {
    flex-direction: column;
    display: flex;
  }
}

.box-img {
  z-index: 2;
  position: relative;
}

.box-img:after {
  z-index: -2;
  content: "";
  height: 100%;
  width: 100%;
  background-image: url("about-img.09f546a2.jpg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 25px;
  right: -8px;
}

.about-area .section-title {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .about-area .section-title {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .about-area .about-left {
    display: none;
  }
}

.about-area .primary-btn.white {
  margin-right: 10px;
}

@media (max-width: 1199px) {
  .about-area .primary-btn.white {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .about-area .primary-btn.white {
    margin-bottom: 0;
  }
}

@media (max-width: 374px) {
  .about-area .primary-btn.white {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  background-color: #1c1b31 !important;
}

.skill-item {
  margin-bottom: 30px;
  margin-left: 30px;
}

@media (max-width: 575px) {
  .skill-item {
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.progress-bar, .progress {
  transition: all 4s;
}

@media (max-width: 576px) {
  .subtle-text {
    display: none;
  }
}

.skill-item h5 {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
}

.subtle-text {
  text-transform: uppercase;
  color: #f8f8f8;
  z-index: -3;
  font-size: 166px;
  font-weight: 900;
  position: absolute;
  bottom: 3.2em;
  left: -.5em;
}

.client-info {
  text-align: center;
  margin-top: 25px;
  padding: 25px 30px;
  position: relative;
}

.client-info:before {
  content: "";
  height: 175px;
  width: 175px;
  background: url("skill-info.38d394c2.html") no-repeat;
  position: absolute;
  top: -25px;
  left: -50px;
}

.client-info .lage {
  color: #1c1b31;
  z-index: 9;
  font-size: 100px;
  font-weight: bolder;
  line-height: 85px;
  position: relative;
}

.client-info .smll {
  text-align: left;
  color: #000;
  width: 100px;
  margin-left: 20px;
  font-size: 20px;
}

.single-brand-item {
  width: 170px;
  height: 100px;
  cursor: pointer;
  box-shadow: none;
  -o-transition: all .4s ease 0s;
  border: 1px solid #eee;
  margin-bottom: 30px;
  transition: all .4s;
}

@media (max-width: 575px) {
  .single-brand-item {
    margin: 0 auto 30px;
  }
}

.single-brand-item .d-table-cell {
  vertical-align: middle;
}

.single-brand-item:hover {
  border: 1px solid #0000;
  box-shadow: 0 8px 30px #7655e126;
}

.bar-null {
  width: 0;
}

.work-area {
  background: #c0c0ca;
}

@media (max-width: 1024px) {
  .work-area .row.mb-80 {
    margin-bottom: 30px;
  }
}

.work-area .section-title {
  margin-bottom: 0;
}

.work-area .filters {
  text-align: center;
}

@media (max-width: 991px) {
  .work-area .filters {
    margin-top: 40px;
  }
}

.work-area .filters ul {
  text-align: right;
  padding: 0;
}

@media (max-width: 991px) {
  .work-area .filters ul {
    text-align: left;
  }
}

.work-area .filters ul li {
  cursor: pointer;
  text-transform: capitalize;
  padding: 0 15px;
  font-size: 13px;
  list-style: none;
  display: inline-block;
  position: relative;
}

.work-area .filters ul li:last-child {
  padding-right: 0;
}

@media (max-width: 991px) {
  .work-area .filters ul li:first-child {
    padding-left: 0;
  }
}

.work-area .filters ul li.active, .work-area .filters ul li:hover {
  color: #2f2d52;
}

.work-area .filters-content .show {
  opacity: 1;
  visibility: visible;
  transition: all .35s;
}

.work-area .filters-content .hide {
  opacity: 0;
  visibility: hidden;
  transition: all .35s;
}

.work-area .filters-content img {
  width: 100%;
  border-radius: 10px;
}

.single-work {
  border-radius: 5px;
  margin-bottom: 30px;
}

.single-work .thumb .overlay-bg {
  opacity: 0;
  -o-transition: all .3s ease 0s;
  background: #2f2d52;
  border-radius: 5px;
  transition: all .3s;
}

.single-work .thumb .image {
  opacity: 1;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all .5s;
  display: block;
}

.single-work .middle {
  opacity: 0;
  text-align: center;
  transition: all .5s;
  -o-transition: all .3s ease 0s;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-work .middle h4 {
  color: #fff;
  font-size: 21px;
}

.single-work .middle .cat {
  color: #fff;
  font-size: 15px;
}

.single-work:hover .thumb .overlay-bg {
  opacity: .8;
}

.single-work:hover .middle {
  opacity: 1;
}

.overlay {
  position: absolute;
  inset: 0;
}

#lightcase-overlay {
  width: 100%;
  min-height: 100%;
  z-index: 2000;
  background: #fff;
  display: none;
  position: fixed;
  top: -9999px;
  bottom: -9999px;
  left: 0;
}

.lightcase-icon-close:before {
  content: "";
  color: #000;
}

[class*="lightcase-icon-"]:before {
  speak: none;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  font-family: Linearicons-Free;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.single-job {
  min-height: 225px;
  -o-transition: all .3s ease 0s;
  background-color: #c0c0ca;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 40px 30px;
  transition: all .3s;
}

@media (max-width: 576px) {
  .single-job .top-sec {
    -moz-flex-direction: column;
    flex-direction: column;
  }
}

.single-job .top-left h4 {
  color: #000;
  -o-transition: all .3s ease 0s;
  font-size: 21px;
  font-weight: 700;
  transition: all .3s;
}

@media (max-width: 1199px) {
  .single-job .top-left h4 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .single-job .bottom-sec {
    margin-top: 30px;
  }
}

.single-job .primary-btn {
  color: #2f2d52;
  background: #fff;
  border: 1px solid #a3cd98;
  padding: 0 28px;
}

@media (max-width: 1199px) {
  .single-job .primary-btn {
    padding: 0 15px;
  }
}

.single-job .primary-btn span, .single-job .primary-btn:before {
  color: #2f2d52;
}

.single-job:hover {
  cursor: pointer;
  color: #fff;
  background: #1c1b31;
}

.single-job > .fa, .single-job:hover .top-left h4 {
  color: #fff;
}

.project-area {
  color: #fff;
  background-color: #263238;
}

.single-project {
  min-height: 225px;
  -o-transition: all .3s ease 0s;
  background-color: #c0c0ca;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 40px 30px;
  transition: all .3s;
}

@media (max-width: 576px) {
  .single-project .top-sec {
    -moz-flex-direction: column;
    flex-direction: column;
  }
}

.single-project .top-left h4 {
  color: #000;
  -o-transition: all .3s ease 0s;
  font-size: 21px;
  font-weight: 700;
  transition: all .3s;
}

@media (max-width: 1199px) {
  .single-project .top-left h4 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .single-project .bottom-sec {
    margin-top: 30px;
  }
}

.single-project .primary-btn {
  color: #2f2d52;
  background: #fff;
  border: 1px solid #a3cd98;
  padding: 0 28px;
}

@media (max-width: 1199px) {
  .single-project .primary-btn {
    padding: 0 15px;
  }
}

.single-project .primary-btn span, .single-project .primary-btn:before {
  color: #2f2d52;
}

.single-project:hover {
  cursor: pointer;
  color: #fff;
  background: #1c1b31;
}

.single-project > .fa, .single-project:hover .top-left h4 {
  color: #fff;
}

.skill-area {
  background: #c0c0ca;
}

.d-table-cell {
  background-color: #fff;
}

.service-area {
  background: #c0c0ca;
}

.service-area.white {
  background: #fff;
}

.service-area .col-lg-3.col-md-6:nth-child(1) .single-service, .service-area .col-lg-3.col-md-6:nth-child(2) .single-service, .service-area .col-lg-3.col-md-6:nth-child(3) .single-service, .service-area .col-lg-3.col-md-6:nth-child(4) .single-service {
  background: #1c1b31;
}

.single-service {
  color: #fff;
  -o-transition: all .3s ease 0s;
  border-radius: 5px;
  padding: 60px 30px;
  transition: all .3s;
}

.single-service span {
  font-size: xx-large;
}

@media (max-width: 1199px) {
  .single-service {
    padding: 30px 15px;
  }
}

@media (max-width: 991px) {
  .single-service {
    margin-bottom: 30px;
    padding: 60px 30px;
  }
}

@media (max-width: 576px) {
  .single-service {
    text-align: center;
  }
}

.single-service .lnr {
  color: #fff;
  -o-transition: all .3s ease 0s;
  font-size: 36px;
  transition: all .3s;
}

.single-service h4 {
  color: #fff;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 700;
  line-height: 27px;
}

.single-service h4 span {
  font-size: 21px;
  display: block;
}

@media (max-width: 767px) {
  .single-service h4 span {
    display: inline-block;
  }
}

.single-service:hover {
  transform: translateY(-10px);
}

.testimonial-body {
  height: 100%;
  flex-flow: column;
  justify-content: space-around;
  display: flex;
}

.slide-progress {
  width: 0;
  max-width: 100%;
  height: 4px;
  background: #7fc242;
}

.testimonials_area {
  padding-bottom: 10px;
  position: relative;
}

.testimonials_area .owl-controls {
  text-align: center;
}

@media (max-width: 767px) {
  .testimonials_area .owl-controls {
    display: none;
  }
}

.testimonials_area .owl-controls .owl-nav {
  position: relative;
  top: 29px;
}

.testimonials_area .owl-controls .owl-nav .owl-prev, .testimonials_area .owl-controls .owl-nav .owl-next {
  color: #000;
  margin-right: 31px;
  display: inline-block;
}

.testimonials_area .owl-controls .owl-nav .owl-prev {
  margin-right: 80px;
}

.testimonials_area .owl-controls .owl-nav .owl-next {
  margin-left: 80px;
  margin-right: 0;
}

.testimonials_area .owl-controls .owl-dots .owl-dot {
  height: 4px;
  width: 40px;
  -o-transition: all .3s ease 0s;
  background: #eee;
  transition: all .3s;
  display: inline-block;
}

.testimonials_area .owl-controls .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.testimonials_area .owl-controls .owl-dots .owl-dot:hover, .testimonials_area .owl-controls .owl-dots .owl-dot.active {
  background: #2f2d52;
}

.testi_slider {
  margin: auto;
}

.testi_slider .testi_item {
  text-align: center;
}

.testi_slider .testi_item img {
  width: 100%;
  max-width: 205px;
  min-height: 100px;
  height: auto;
  border-radius: 500px;
  margin: auto;
}

.testi_item {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .testi_item {
    margin-bottom: 0;
  }
}

.testi_item h4 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
}

.testi_item .list {
  margin-bottom: 35px;
}

.testi_item .list li {
  display: inline-block;
}

.testi_item .list li a {
  color: #ffc000;
  font-size: 14px;
}

.testi_item p {
  max-width: 570px;
  margin: auto;
}

.contact-area {
  background: url("contact-bg.cc6dc30a.png") center / cover;
}

@media (max-width: 1024px) {
  .contact-area .row.mt-80 {
    margin-top: 40px;
  }
}

.contact-area .contact-title {
  color: #fff;
  text-align: center;
}

.contact-area .contact-title h2 {
  color: #fff;
  margin-bottom: 45px;
  font-size: 48px;
  line-height: 45px;
  position: relative;
}

.contact-area .contact-title h5 {
  color: #fff;
  text-align: left;
}

.contact-area .contact-title .contact-details {
  text-align: left;
  margin-bottom: 5px;
}

.icon-space {
  width: 40px;
  margin-top: 10px;
  margin-right: 10px;
}

.gift {
  background-color: #0000;
  background-image: none, url("joanna-kosinska-3BgkveM3y_k-unsplash.445ff367.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.gift, h3 {
  text-align: left;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tint {
  height: 100%;
  width: 100%;
  background-color: #000000b3;
  top: 0;
  left: 0;
}

@media (max-width: 1024px) {
  .contact-area .contact-title h2 {
    margin-bottom: 30px;
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .contact-area .contact-title h2 {
    font-size: 30px;
  }
}

.contact-area .contact-title h2:before {
  content: "";
  width: 100px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .contact-area .contact-title h2:before {
    bottom: -5px;
  }
}

.contact-area .contact-title h2:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 0;
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .contact-area .contact-title h2:after {
    bottom: -9px;
  }
}

.contact-area .contact-title p {
  max-width: 540px;
  margin: 0 auto;
}

.contact-area .primary-btn {
  color: #000;
  background: #fff;
  border: 1px solid #fff;
}

.contact-area .primary-btn:before, .contact-area .primary-btn span {
  color: #000;
}

.contact-area .primary-btn:hover {
  background: none;
}

.contact-area .primary-btn:hover span {
  color: #fff;
}

.contact-page-area .map-wrap {
  margin-top: 80px;
}

@media (max-width: 991px) {
  .contact-page-area .map-wrap {
    margin-top: 40px;
  }
}

.contact-page-area .contact-btns {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  padding: 40px;
  font-size: 18px;
  font-weight: 600;
}

.contact-page-area .contact-btns:hover {
  color: #fff;
}

@media (max-width: 991px) {
  .contact-page-area .form-area .form-group-top {
    margin-bottom: 0;
  }
}

.contact-page-area .form-area input {
  padding: 25px;
}

.contact-page-area .form-area input, .contact-page-area .form-area textarea {
  border-radius: 5px;
  font-size: 12px;
}

.contact-page-area .form-area input:focus, .contact-page-area .form-area textarea:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.contact-page-area .form-area textarea {
  height: 195px;
  margin-top: 0;
}

.contact-page-area .form-area .primary-btn {
  padding: 0 20px;
  font-size: 13px;
}

.contact-page-area .form-area ::-webkit-input-placeholder {
  font-weight: 500;
}

.contact-page-area .form-area :-moz-placeholder {
  font-weight: 500;
}

.contact-page-area .form-area ::-moz-placeholder {
  font-weight: 500;
}

.contact-page-area .form-area :-ms-input-placeholder {
  font-weight: 500;
}

.contact-page-area .form-area ::-moz-placeholder {
  font-weight: 500;
}

.contact-page-area .single-contact-address {
  margin-bottom: 10px;
}

.contact-page-area .single-contact-address h5 {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
}

.contact-page-area .single-contact-address .lnr {
  color: #2f2d52;
  margin-right: 30px;
  font-size: 30px;
  font-weight: 500;
}

.footer-area {
  padding: 100px 0;
}

@media (max-width: 1024px) {
  .footer-area {
    padding: 60px 0;
  }
}

.footer-area .footer-logo {
  text-align: center;
}

.footer-area .footer-logo h4 {
  color: #eaf1f9;
  margin-top: 48px;
  font-size: 21px;
  font-weight: 700;
}

.footer-area .footer-social {
  text-align: center;
  margin: 25px 0 50px;
}

@media (max-width: 1024px) {
  .footer-area .footer-social {
    margin: 15px 0 30px;
  }
}

.footer-area .footer-social a {
  padding: 0 20px;
}

.footer-area .footer-social a i {
  color: #eaf1f9;
  -o-transition: all .3s ease 0s;
  font-size: 36px;
  transition: all .3s;
}

@media (max-width: 576px) {
  .footer-area .footer-social a i {
    font-size: 25px;
  }
}

.footer-area .footer-social a i:hover {
  color: #2f2d52;
}

.footer-area .footer-bottom {
  text-align: center;
}

.footer-area .footer-bottom p {
  margin-bottom: 0;
}

.footer-area .footer-bottom a {
  color: #2f2d52;
}

#back-top {
  cursor: pointer;
  z-index: 9999999;
  opacity: 0;
  -o-transition: all .3s ease 0s;
  transition: all .3s;
  position: fixed;
  bottom: 30px;
  right: 0;
}

#back-top a {
  text-align: center;
  width: 35px;
  height: 40px;
  -o-transition: all .3s ease 0s;
  background: #1c1b31;
  border-radius: 5px;
  padding: 12px 8px 8px 7px;
  transition: all .3s;
  display: block;
}

#back-top a i {
  color: #fff;
  font-size: 22px;
}

#back-top a:hover {
  box-shadow: 0 8px 12px #9c9c9cb3;
}

.back-top-animation {
  opacity: 1 !important;
  -o-transition: all .4s ease-in !important;
  transition: all .4s ease-in !important;
  right: 20px !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.icon-bar {
  z-index: 9;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.icon-bar a {
  text-align: center;
  color: #fff;
  padding: 16px;
  font-size: 20px;
  transition: all .3s;
  display: block;
}

.icon-bar a:hover {
  background-color: #000;
}

@media (max-width: 576px) {
  .icon-bar {
    top: unset;
    width: 100%;
    height: 35px;
    display: flex;
    bottom: 0;
    transform: translateY(0%);
  }

  .icon-bar a {
    flex: auto;
    padding: 10px;
  }
}

.facebook {
  color: #fff;
  background: #3b5998;
}

.twitter {
  color: #fff;
  background: #55acee;
}

.google {
  color: #fff;
  background: #dd4b39;
}

.linkedin {
  color: #fff;
  background: #007bb5;
}

.youtube {
  color: #fff;
  background: #b00;
}

.whatsapp {
  color: #fff;
  background: #25d366;
}

.flat {
  height: 0;
  margin: 0;
  padding: 0;
  display: none;
}

/*# sourceMappingURL=index.f6afa18f.css.map */
